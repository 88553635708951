<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <h2>
          <button
            class="back__btn"
            @click="$router.go(-1)"
            v-if="$route.meta.header == 'Industry Users'">
            <img
              width="12px"
              height="15px"
              src="@/assets/Arrow - Right.svg"
              alt="" />
            Back
          </button>
          {{ $route.meta.header }}
        </h2>
        <div class="side_nav_wrapper">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path.includes('industries') ? '__side_item_active' : '',
              ]"
              @click="
                $router
                  .replace('/admin/reporting/industries')
                  .catch((err) => {})
              "
              v-if="!disallowedUsers">
              Industries
            </button>
            <!-- <button class="btn" :class="[$route.path  == '/admin/reporting/quality_routes' ? '__side_item_active' : '']" @click="$router.replace('/admin/reporting/quality_routes').catch(err => { })">
                           Quality Routes
                        </button> -->
            <button
              class="btn"
              :class="[
                $route.path == '/admin/reporting/sender_names'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/reporting/sender_names')
                  .catch((err) => {})
              "
              v-if="!disallowedUsers">
              Sender Names
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/reporting/message_type'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/reporting/message_type')
                  .catch((err) => {})
              ">
              Message Type
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/reporting/message_summary'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/reporting/message_summary')
                  .catch((err) => {})
              ">
              Message Summary
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/reporting/sales&market_BI'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/reporting/sales&market_BI')
                  .catch((err) => {})
              "
              v-if="!disallowedUsers">
              Sales and Market BI
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/reporting/business_chasis'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/reporting/business_chasis')
                  .catch((err) => {})
              "
              v-if="!disallowedUsers">
              Business Chassis
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/reporting/theo-bot'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/reporting/theo-bot').catch((err) => {})
              "
              v-if="!disallowedUsers">
              TheoBot Report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  export default {
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
  };
</script>
